@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

.projects-section {
  padding: 2rem;
  background: #0d1b2a;
  color: #d4d4f7;
  text-align: center;
}

.projects-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.project-list {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0d1b2a;
  border-radius: 8px;
  overflow: hidden;
  width: 45%;
  max-width: 400px;
  text-align: left;
  padding: 1rem;
  transition: background-color 0.3s ease;
}

.project:hover {
  background: #14263f;
}

.project:hover .project-info h3 {
  color: #50bfc3;
}

.project-image-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 200px;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-info {
  padding: 1rem;
}

.project-info h3 {
  font-size: 1.5rem;
  margin: 1rem 0;
  transition: color 0.3s ease;
}

.project-info p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.project-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.tech-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.tech {
  background: transparent;
  color: #d4d4f7;
  border: 1px solid #d4d4f7;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.project:hover .tech {
  color: #50bfc3;
  border-color: #50bfc3;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #d4d4f7;
  transition: color 0.3s ease, transform 0.3s ease;
  margin-top: 15px;
}

.icon:hover {
  color: #50bfc3;
  transform: scale(1.2);
}

.icon svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}
