@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #0d1b2a;
  color: #d4d4f7;
  text-align: center;
  padding-top: 10px;
}

.about-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 800px;
}

.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  padding: 1rem;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.4s ease;
}

.skill .icon {
  width: 80px;
  height: 80px;
  transition: transform 0.4s ease;
  color: #50bfc3;
}

.skill:hover {
  transform: scale(1.4);
}

.skill:hover .icon {
  transform: rotate(15deg);
}

.skill p {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: #d4d4f7;
}
