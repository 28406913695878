header {
  background: rgba(6, 14, 32, 0.8); /* transparant */
  color: #d4d4f7;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  padding-right: 100px;
  backdrop-filter: blur(10px);
  border: none;
}

.left-content {
  display: flex;
  align-items: center;
}

.name {
  color: #d4d4f7;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.name:hover {
  color: #50bfc3;
}

nav {
  display: flex;
  align-items: center;
  width: 100%;
}

.nav-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center; /* Center the nav links */
  flex: 1;
}

.nav-links li {
  margin: 0 0.5rem; /* Adjust margin between links to bunch them up */
}

.nav-links li a {
  color: #d4d4f7;
  text-decoration: none;
  padding: 0.5rem 1.5rem; /* Adjust padding for better click area */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and color */
  border-radius: 5px; /* Slightly curved corners */
  display: inline-block;
}

.nav-links li a:hover {
  background-color: #50bfc3; /* Turquoise background on hover */
  color: #060e20; /* Dark navy text on hover */
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.icon {
  width: 30px;
  height: 30px;
  color: #d4d4f7; /* Slightly blue-tinted white */
  transition: color 0.3s ease, transform 0.3s ease;
}

.icon:hover {
  color: #50bfc3; /* Turquoise color on hover */
  transform: scale(1.2); /* Slightly enlarge on hover */
}

.icon svg {
  fill: currentColor; /* Ensure the SVG uses the current text color */
  width: 100%;
  height: 100%;
}
